<template>
  <div class="fixed-0 z-toast flex" :class="positions[_position]">
    <base-dialog-root default-open @update:open="$emit('resolve', true)">
      <base-dialog-overlay
        class="vf-wwb4o6"
        data-test-id="vf-panel-backdrop"
        :style="!hideBackdrop ? 'background: #00000040' : ''"
      />
      <vf-dialog-content
        class="vf-7m1swo"
        :class="[transforms[_position], classContent, { [panelHeights[_size]]: ['top', 'bottom'].includes(_position) }]"
        style="--un-backdrop-brightness: brightness(0.25)"
        :style="styleContent"
      >
        <slot />
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass, Responsive } from '#types/common'
import type { SizesSubUnion } from '#types/sizes'
import type { DialogBaseOptions } from '#types/config/components/dialog'

type Size = SizesSubUnion<'sm' | 'lg' | 'full'>

export type Props = {
  /**
   * Position of the panel.
   */
  position?: DialogBaseOptions['position']
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Classes to be applied to close button.
   */
  classClose?: CSSClass
  /**
   * Size of the panel.
   */
  size?: Size | Responsive<Size>
  styleContent?: string
  variantClose?: 'primary' | 'secondary' | 'none'
  /**
   * If backdrop is shown.
   */
  hideBackdrop?: boolean
}

const { position, size } = withDefaults(defineProps<Props>(), {
  position: () => ({ sm: 'bottom', lg: 'right' }),
  size: () => ({ sm: 'lg', lg: 'full' }),
  variantClose: 'primary'
})

defineEmits<{
  resolve: [backdropClicked?: boolean]
}>()

const { $viewport } = useNuxtApp()

const transforms = {
  top: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-y-20',
  bottom: '[.nested-enter-from_&,.nested-leave-to_&]:translate-y-20',
  left: '[.nested-enter-from_&,.nested-leave-to_&]:-translate-x-20',
  right: '[.nested-enter-from_&,.nested-leave-to_&]:translate-x-20'
}

const positions = {
  bottom: 'items-end justify-center',
  left: 'justify-start',
  right: 'justify-end'
}

const panelHeights = {
  full: 'h-full',
  lg: 'h-9/10',
  sm: 'h-1/2'
}

const _position = computed(() => getValueForBreakpoint($viewport.breakpoint, position)!)
const _size = computed(() => getValueForBreakpoint($viewport.breakpoint, size)!)
</script>

<template>
  <base-collapse v-model="isExpanded" class-toggle="w-full" @click="handleCollapse">
    <template #toggle="{ expanded }">
      <component
        :is="hasSubItems ? 'div' : BaseLink"
        class="flex items-center b-t b-grey-70 px-4 between duration"
        :class="[
          brandClasses.title,
          { 'bg-white': !expanded }, hasSubItems ? 'py-3' : 'py-4',
        ]"
        v-bind="hasSubItems ? {} : {
          to: content.target?.url,
          target: content.target?.targetAttribute,
        }"
        @click="hasSubItems ? undefined : $event.stopPropagation() && $emit('click', content.title)"
      >
        <span :class="presets?.[content.menuStyle!]">{{ content.title }}</span>
        <vf-icon v-if="hasSubItems" :dir="expanded ? 'up' : 'down'" name="chevron" size="lg" />
      </component>
    </template>
    <base-link
      v-if="hasSubItems && !content.hideShopAll?.sm"
      class="block px-4"
      :class="brandClasses.shopAll"
      :target="content.target?.targetAttribute"
      :to="content.target?.url"
      @click.stop="$emit('click', content.title)"
    >
      {{ content.overrideShopAll || `${$t.megaMenuShopAll} ${content.title}` }}
      <vf-icon class="hidden !inline" name="arrow" size="md" />
    </base-link>
    <template v-for="(column, i) in content.columns" :key="i">
      <cms-section
        v-for="menuItem in column.children"
        :key="menuItem.id"
        :section="{ name: menuItem.id, items: [{ ...menuItem, isFullWidth: true }] }"
      />
    </template>
  </base-collapse>
</template>

<script lang="ts" setup>
import type { MenuItem } from '#types/components/cms/mega-menu'
import { BaseLink } from '#components'

const { content } = defineProps<{
  content: MenuItem
}>()

const emit = defineEmits<{
  click: [label: string]
  close: [label: string]
  expand: [label: string]
}>()

const { brandClasses, presets } = useAppConfig().components.cms.megaMenuL1Mobile

const isExpanded = ref(false)

const handleCollapse = () => {
  isExpanded.value ? emit('expand', content.title) : emit('close', content.title)
}

// simplify when bugfix https://digital.vfc.com/jira/browse/GLOBAL15-82870 will be done
const hasSubItems = content.columns?.some((column) => Object.keys(column).length)
</script>

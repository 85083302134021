<template>
  <cms-recommendation-carousel
    :currency="currency[locale]"
    :on-product-click="handleProductClick"
    :products="recommendedProducts"
    :quickshop
    :title="content.title"
    :variant="content.variant"
  />
</template>

<script lang="ts" setup>
import type { ProductRecommendations } from '#types/components/cms/product-recommendations'
import type { MonetateExtractedProduct } from '#types/p13n'

const props = defineProps<{ content: ProductRecommendations }>()

const { currency } = useRuntimeConfig().public
const { sendMonetateEvents, sendRecImpressionsEvent, extractProduct } = useMonetate()
const { $gtm } = useNuxtApp()
const locale = useLocale()
const { enableQuickShop } = useAppConfig().components.cms.productRecommendations

const quickshop = props.content.quickShopVisibility ? props.content.quickShopVisibility === 'visible' : enableQuickShop

const recommendedProducts = computed<MonetateExtractedProduct[]>(() =>
  props.content.recommendedProducts?.map((product) =>
    extractProduct(product, props.content.showRatings)
  )
)

const list = 'Product Recommendation Carousel'

const handleProductClick = (product: any) => {
  const recToken = product.recToken
  sendMonetateEvents([{
    eventType: 'monetate:record:RecClicks',
    recClicks: [recToken]
  }])

  const clickedRecommendation = props.content.recommendedProducts.find(
    (p) => p.recToken === recToken
  )

  if (clickedRecommendation) {
    $gtm.push('product.onProductRecClick', clickedRecommendation, {
      action: 'Click Recommendation',
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list,
      title: props.content.title,
      vendor: 'monetate'
    })
  }
}

onMounted(() => {
  if (props.content.monetateDecision?.name !== 'Control' && props.content.recommendedProducts?.length) {
    sendRecImpressionsEvent(props.content.recommendedProducts, 24)
    $gtm.push('product.onProductRecImpressions', props.content.recommendedProducts, {
      currency: currency[locale],
      experience: props.content.monetateDecision,
      list,
      title: props.content.title
    })
  }
})
</script>

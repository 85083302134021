<template>
  <div v-show="products?.length || loading" class="pb-3 lg:pb-6">
    <div v-if="title" class="mx-4 flex items-center pb-2 pt-6 between lg:mx-6 lg:pb-4">
      <h2 class="body-2">
        {{ title }}
      </h2>
      <div v-if="carouselRef?.carousel" class="flex <md:hidden">
        <base-button
          :aria-label="$t.previous"
          class="rounded-sm p-1 duration"
          :class="carouselRef.carousel.canScrollPrev ? '@hactive:bg-brand-2' : 'c-grey-60'"
          :disabled="!carouselRef.carousel.canScrollPrev"
          @click="carouselRef?.scrollPrev()"
        >
          <vf-icon dir="left" name="chevron" size="md" />
        </base-button>
        <base-button
          :aria-label="$t.next"
          class="rounded-sm p-1 duration"
          :class="carouselRef.carousel.canScrollNext ? '@hactive:bg-brand-2' : 'c-grey-60'"
          :disabled="!carouselRef.carousel.canScrollNext"
          @click="carouselRef?.scrollNext()"
        >
          <vf-icon dir="right" name="chevron" size="md" />
        </base-button>
      </div>
    </div>
    <vf-carousel-elevate
      ref="carouselRef"
      :class-container="brandClasses.carouselContainer"
      :class-controls="brandClasses.carouselControls"
      :show-arrows="$viewport.md && !title"
      :show-arrows-on-hover-only="$viewport.md && !title"
      size-controls="md"
      :slides-to-scroll="!$viewport.md ? 'auto' : (variant === '6-cards' ? 6 : 3)"
    >
      <template v-if="!products.length">
        <div
          v-for="i in 6"
          :key="i"
          v-style:w="variant === '6-cards'
            ? { sm: '74.66%', md: '15.14%', lg: '20%' }
            : variant === 'search-hub'
              ? { sm: '44.44%', md: '30.03%', lg: '20%' }
              : { sm: '74.66%', md: '29.49%', lg: '29.58%' }"
          class="children:skeleton pr-1"
        >
          <div style="aspect-ratio: 0.72" />
        </div>
      </template>
      <template v-else>
        <div
          v-for="product in products"
          :key="product.id"
          v-style:w="variant === '6-cards'
            ? { sm: '74.66%', md: '15.14%', lg: '20%' }
            : variant === 'search-hub'
              ? { sm: '44.44%', md: '30.03%', lg: '20%' }
              : { sm: '74.66%', md: '29.49%', lg: '29.58%' }"
          class="pr-1"
          @click="onProductClick?.(product)"
        >
          <product-card-recommended :currency="currency" v-bind="product" :gtm-config="{ list: _list, category: _list }" :quickshop />
        </div>
      </template>
    </vf-carousel-elevate>
  </div>
</template>

<script lang="ts" setup>
const { title } = withDefaults(defineProps<{
  products: any[]
  loading?: boolean
  title?: string
  variant?: '3-cards' | '6-cards' | 'search-hub'
  currency: string
  quickshop?: boolean
  onProductClick?: (product: any) => void
}>(), { quickshop: true, variant: '6-cards' })

defineEmits<{ (e: 'product-click', product: any): void }>()

const { brandClasses } = useAppConfig().components.cms.productRecommendations
const carouselRef = ref()

const _list = getRecommendationListDescription({ list: 'Product Recommendation Carousel', title })
</script>

<template>
  <div>
    <h2
      v-if="title"
      v-style:c="titleColor"
      class="mb-4"
      :class="[titleStyle, { 'px-4': !style }]"
    >
      {{ title }}
    </h2>
    <vf-accordion
      v-for="(accordion, i) in accordions"
      :key="i"
      :icon="iconsStyle"
      :icon-collapse="iconsMap[iconsStyle]"
      :title="accordion.title"
      :variant="style"
    >
      <div class="body-3 pb-3 pb-6">
        <lazy-cms-rich-text :content="{ richText: accordion.richText }" />
      </div>
    </vf-accordion>
  </div>
</template>

<script lang="ts" setup>
import type { AccordionsContent } from '#types/components/cms/accordions'

const { content } = defineProps<{ content: AccordionsContent }>()
const {
  accordions,
  iconsStyle = 'chevron',
  style,
  title,
  titleColor,
  titleStyle,
} = content

const iconsMap = {
  plus: 'minus',
}
</script>

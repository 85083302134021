<template>
  <div>
    <div class="skeleton" :style="{ 'aspect-ratio': mediaSize.width / mediaSize.height }" />
    <div class="p-2 space-y-2">
      <div class="h-4 w-20 skeleton" />
      <div class="h-5 w-30 skeleton" />
      <div class="h-2 w-14 skeleton py-1" />
      <div class="h-4 w-16 skeleton" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const { mediaSize } = useAppConfig().components.product.card
</script>

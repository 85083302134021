<template>
  <article class="relative h-full f-col overflow-hidden">
    <base-picture
      :alt="image.alt"
      v-bind="size"
      class="w-full overflow-hidden"
      :src="getResponsiveImageTransformations(image.src, {
        ...size,
        ...transformations,
      })"
    />
    <div class="f-col gap-1 p-2">
      <p class="line-clamp-2 subtitle-4">
        <base-link
          :to="{
            path: url,
            state: { category: `Search: ${searchType}`, searchType, suggestedPhrase },
          }"
          @click="setInteractionOrigin('search')"
        >
          {{ title }}
          <span class="absolute-0" />
        </base-link>
      </p>
      <product-pricing class="subtitle-5" :currency :price />
    </div>
    <base-button
      v-if="quickshop"
      :aria-label="$t.add"
      class="group absolute right-0 top-0 z-3"
      data-test-id="product-card-add"
      @click="PanelQuickshop.open({ product, closeOnAdd: closeQuickshopOnAdd, gtmConfig })"
    >
      <span class="m-1 block rounded-sm p-1 duration lg:m-2 group-hover:bg-brand-2">
        <vf-icon :height="{ sm: '20', lg: '24' }" name="plus" :width="{ sm: '20', lg: '24' }" />
      </span>
    </base-button>
    <panel-quickshop />
  </article>
</template>

<script lang="ts" setup>
import type { HistoryState } from 'vue-router'
import type { ProductVariants } from '#root/api/clients/product/data-contracts'
import type { ImageItem } from '#types/media'
import type { PriceFull } from '#types/product'
import type { ProductContext } from '#types/gtm'

const { title, url, image, id, currency, price, quickshop } = withDefaults(defineProps<{
  /**
   * Product name or title
   */
  title: string
  /**
   * Path to product detail page
   */
  url: string
  /**
   * Default product image
   */
  image: ImageItem
  /**
   * Product id
   */
  id: string
  /**
   * Product price currency
   */
  currency: string
  /**
   * Product price data
   */
  price: PriceFull
  /**
   * Quickshop Toggle
   */
  quickshop: boolean
  gtmConfig: ProductContext
}>(), { quickshop: true })

const { presets: { size, transformations } = {} } = useAppConfig().components.cms.productRecommendations
const route = useRoute()

const searchType = inject('searchType', undefined) as HistoryState | undefined
const suggestedPhrase = inject('suggestedPhrase', undefined) as HistoryState | undefined

const product = computed(() => ({
  id,
  name: title,
  url,
  price,
  currency,
  attributes: [],
  variants: {} as ProductVariants,
}))

const closeQuickshopOnAdd = route.name?.toString().includes('cart')

const PanelQuickshop = createPanel('quickshop')
</script>

<template>
  <div data-dynatrace="product-price" data-test-id="product-pricing">
    <span
      class="gap-x-2 wrap i-flex"
      :class="{ 'md:f-col md:gap-y-1': block }"
    >
      <template v-if="isRange && !showDiscountedRanges">
        <span :class="classCurrent">
          {{ current }}
        </span>
      </template>
      <template v-else>
        <component
          :is="current === original ? 'span' : 'del'"
          v-if="!isOmnibus || !showOmnibusDetails || compactOmnibus"
          :class="
            current === original
              ? classCurrent
              : brandClasses.original"
        >
          {{ isOmnibus ? historicBest : original }}
        </component>
        <del v-if="price.previousBestSalesPrice" :class="[classCurrent, brandClasses.sale]">
          {{ useFormattedPrice(price.previousBestSalesPrice, currency) }}
        </del>
        <ins v-if="current !== original" :class="[classCurrent, brandClasses.sale]" style="text-decoration: none">
          {{ current }}
          <template v-if="showDiscountPercentage && !isRange && (!showOmnibusDetails || compactOmnibus)">
            ({{ isOmnibus ? historicDiscountPercentage : discountPercentage }})
          </template>
        </ins>
      </template>
    </span>
    <transition name="slide">
      <div v-if="isOmnibus && showOmnibusDetails">
        <div v-if="!compactOmnibus" class="mt-1 w-fit flex gap-x-1 text-xs">
          {{ $t.lowestRecentPrice }}: <del class="fw-bold">{{ historicBest }}</del>
          <span v-if="showDiscountPercentage" class="c-brand-3">
            ({{ historicDiscountPercentage }})
          </span>
        </div>
        <div v-if="showOriginalPriceForOmnibus" class="mt-1 w-fit flex gap-x-1 text-xs">
          {{ $t.originalPrice }}: <del class="fw-bold">{{ original }}</del>
          <span v-if="showDiscountPercentage" class="c-brand-3">
            ({{ discountPercentage }})
          </span>
          <vf-toggletip placement="top-start">
            <template #trigger>
              <vf-icon name="info" />
            </template>
            {{ $t.omnibusTooltip }}
          </vf-toggletip>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import type { PriceFull } from '#types/product'
import type { CSSClass } from '#types/common'

const props = withDefaults(defineProps<{
  /**
   * Price to display
   * Example of strike through sales price range and current price range.
   *
   * $20-$40 - Original Price range: - strike through
   * $10-$30 - Current Range.
   * $20 - lowOriginal
   * $40 - highOriginal
   * $10 - lowCurrent
   * $30 - highCurrent
   */
  price: PriceFull
  /**
   * Currency of the price
   */
  currency: string
  classCurrent?: CSSClass
  /**
   * Show or hide the omnibus details, use for drawer interaction
   * Default: true
   */
  showOmnibusDetails?: boolean
  /**
   * Sets displaying the discount amount as percentage
   */
  showDiscountPercentage?: boolean
  /**
   * Shows box with original price and omnibus tooltip below sale price.
   * Currently only shown on PDP and Quickshop
   */
  showOriginalPriceForOmnibus?: boolean
  /**
   * Show original price and discounted price below it
   * Currently only use on order Details
   */
  block?: boolean
  compactOmnibus?: boolean
}>(), {
  showOmnibusDetails: true
})

const { brandClasses, showDiscountedRanges } = useAppConfig().components.product.pricing
const { $feature, $t } = useNuxtApp()

const getPrice = (low: number, high: number) => {
  if (low === high)
    return low === 0 ? $t.free : useFormattedPrice(low, props.currency)
  return `${useFormattedPrice(low, props.currency)} - ${useFormattedPrice(high, props.currency)}`
}

const isOmnibus = computed(() => {
  const { historicBestPrice, lowCurrent, lowOriginal } = props.price
  return (
    $feature.showOmnibusPricing
    && historicBestPrice !== undefined
    && lowCurrent < lowOriginal
    && lowCurrent < historicBestPrice
  )
})

const original = computed(() => getPrice(props.price.lowOriginal, props.price.highOriginal))

const current = computed(() => getPrice(props.price.lowCurrent, props.price.highCurrent))

const historicBest = computed(() => useFormattedPrice(props.price.historicBestPrice ?? 0, props.currency))

const isRange = computed(() => props.price.lowCurrent !== props.price.highCurrent)

const getFormattedDiscount = (percentage: number) => `${$feature.showNegativeDiscountPercentageOnCatalog ? '-' : ''}${percentage}%`

const historicDiscountPercentage = computed(() => {
  const { percentagePriceDiscount } = props.price
  return percentagePriceDiscount ? getFormattedDiscount(percentagePriceDiscount) : ''
})

const discountPercentage = computed(() => {
  const { highOriginal, lowCurrent } = props.price
  const percentage = getPercentage(highOriginal, highOriginal - lowCurrent, 0)
  return getFormattedDiscount(percentage)
})
</script>
